
import { Delegates, Services } from "@/api";
import { Gender } from "@/api/types";
import Card from "@/components/common/Card.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";
import Table from "@/components/common/Table.vue";
import CompaniesAssign from "@/components/companies/CompaniesAssign.vue";
import DelegateDocuments from "@/components/delegates/DelegateDocuments.vue";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    CompaniesAssign,
    PageTitle,
    Table,
    Card,
    ValidationObserver,
    ValidationProvider,
    DelegateDocuments,
    PhoneInput
  },
  mixins: [PhoneCodes]
})
export default class DelegatesCreatePage extends Vue {
  tab = 0;
  delegate = {
    title: "",
    name: "",
    middleName: "",
    surname: "",
    genderId: 1,
    passport: "",
    birthday: moment().format("YYYY-MM-DD"),
    equityCode: "",
    nationalityCode: "",
    alternativeId: "",
    alternativeType: "",
    residentialStatusCode: "",
    homeLanguageCode: [],
    disabilityCode: "",
    homeAddress: "",
    homeAddressPostalCode: "",
    postalAddress: "",
    postalAddressPostalCode: "",
    phone: "",
    cellPhone: "",
    email: "",
    driversLicenseCode: "",
    licenceExpiryDate: moment().format("YYYY-MM-DD"),
    provinceCode: "",
    textNote: "",
    companyId: "0",
    vaultAccess: false,
    watchSelfResults: false
  };

  genders: Array<Gender> = [];
  titles: Array<string> = [];
  alternativeTypes: Array<string> = [];
  languageCodes: Array<string> = [];
  provinceCodes: Array<string> = [];
  licenseCodes: Array<string> = [];
  equityCodes: Array<string> = [];
  disabilityCodes: Array<string> = [];
  nationalityCodes: Array<string> = [];
  citizenResidentStatusCodes: Array<string> = [];
  delegateId: string | null = null;

  menu = false;
  menu1 = false;
  postalSameAsHome = false;
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save"
    }
  ];
  headersTitle = [
    { icon: "mdi-calendar-month", header: "Delegate Profile Editor" }
  ];

  get dateFormatedBirthday() {
    return moment(this.delegate.birthday).format("MM/DD/YYYY");
  }
  get dateFormatedlicenceExpiry() {
    return moment(this.delegate.licenceExpiryDate).format("MM/DD/YYYY");
  }

  @Watch("postalSameAsHome")
  postalSameAsHomeHandler(val: boolean) {
    if (val) {
      this.delegate.postalAddress = this.delegate.homeAddress;
      this.delegate.postalAddressPostalCode = this.delegate.homeAddressPostalCode;
    } else {
      this.delegate.postalAddress = "";
      this.delegate.postalAddressPostalCode = "";
    }
  }

  async created() {
    try {
      const servise: any = await Services.list();
      this.genders = servise.genders;
      this.titles = servise.titles;
      this.alternativeTypes = servise.alternativeTypes;
      this.languageCodes = servise.homeLanguageCodes;
      this.provinceCodes = servise.provinceCode;
      this.citizenResidentStatusCodes = servise.citizenResidentStatusCode;
      this.licenseCodes = servise.licenseCode;
      this.equityCodes = servise.equityCode;
      this.disabilityCodes = servise.disabilityCode;
      this.nationalityCodes = servise.nationalityCode;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  setDateOfBirth() {
    if (this.delegate.passport.length >= 6 && !isNaN(+this.delegate.passport)) {
      let splitedId: any = this.delegate.passport;
      splitedId = this.delegate.passport.match(/.{1,2}/g);
      const year =
        splitedId[0] > 15 ? "19" + splitedId[0] : "20" + splitedId[0];
      const month = splitedId[1] > 12 ? 12 : splitedId[1];
      const day = splitedId[2] > 31 ? 31 : splitedId[2];

      const preFormattedDate = `${year}-${month}-${day}`;

      this.delegate.birthday = preFormattedDate;
    }
  }
  async save() {
    try {
      const { textNote, ...data } = JSON.parse(JSON.stringify(this.delegate));
      data.birthday = moment(this.delegate.birthday).format("DD-MM-YYYY");
      if (textNote.length > 0) {
        data.textNote = textNote;
      }

      const delegate = await Delegates.create(data);
      await this.$success(
        "<strong>Success!</strong> Delegate has been created!"
      );

      this.tab += 1;
      this.delegateId = delegate.id;
      // if (this.$route.name === "DelegatesCreate") {
      //   this.$router.push({ name: "DelegatesList" });
      // }
    } catch (e) {
      const err = e as any;
      (this.$refs.delegateForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }
  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }
  async onGoBack() {
    await this.$router.push({ name: "DelegatesList" });
  }
  homePostalCodeHandler(val: string) {
    if (this.postalSameAsHome) {
      this.delegate.postalAddressPostalCode = val;
    }
  }
  homeAddressHandler(val: string) {
    if (this.postalSameAsHome) {
      this.delegate.postalAddress = val;
    }
  }
}
