
import { Companies } from "@/api";
import { CompaniesWithPagination, Company } from "@/api/types";
import { Component, ModelSync, Vue, Watch } from "vue-property-decorator";
import PageTitle from "../common/PageTitle.vue";
type TFilters = {
  page: number;
  companyName?: string;
};

@Component({
  components: { PageTitle }
})
export default class CompaniesAssign extends Vue {
  headersTitle = [{ icon: "mdi-account-box", header: "Assign to Company" }];
  companies: Array<Company> = [];
  companiesWithPagination: CompaniesWithPagination = {
    meta: {
      perPage: 12
    }
  };
  filters: TFilters = {
    page: 1,
    companyName: ""
  };
  options = {};
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Email",
      value: "representativeEmail"
    },
    {
      text: "Phone",
      value: "representativePhone"
    },
    {
      text: "Address",
      value: "address"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "50px"
    }
  ];
  @ModelSync("selected", "change", { type: String })
  selectedValue!: number | string;

  @Watch("filters", { deep: true })
  async updFilters(filter: TFilters) {
    await this.loadCompaniesData(filter);
  }

  @Watch("options", { deep: true })
  async onOptionsChange(filters: TFilters) {
    this.filters.page = filters.page;
    await this.loadCompaniesData(filters);
  }
  async loadCompaniesData(filters: any) {
    try {
      const companiesWithPagination: any = await Companies.getListCompanyByType(
        1,
        filters
      );
      // const company = (await Companies.getListCompanyByType(1)).data;
      this.companies = companiesWithPagination.data
        ? companiesWithPagination.data
        : [];
      this.companiesWithPagination = companiesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  onAssign(id: number | string) {
    this.selectedValue = id;
  }
}
